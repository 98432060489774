import {
  Box,
  Button,
  Field,
  Form,
  Modal,
  TextField,
  Typography,
} from "components";
import { compose, withFormik, withHooks, withTranslation } from "enhancers";
import { TFunction } from "i18next";

import styled from "styled-components";
import { AppColor } from "theme/app-color";
import { Yup, gql, homePath, paths } from "utils/helper";

import { ReactComponent as ScgLogo } from "assets/image/scg_logo.svg";

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
`;

interface ResetPasswordProps {
  handleClickBack: () => void;
  t: TFunction;
  isResetPasswordDisabled: boolean;
}

const ResetPasswordPageComponent = (props: ResetPasswordProps) => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    minWidth={357}
    width="100%"
    height={900}
    bgcolor={AppColor["Background/Light Grey"]}
  >
    <Box width={520} bgcolor={AppColor["White / White"]} borderRadius="8px">
      <Container>
        <ScgLogo />
        <div style={{ marginTop: 16 }}>
          <Typography variant="h4">{props.t(".title")}</Typography>
        </div>
        <div style={{ marginBottom: 24, marginTop: 8 }}>
          <Typography variant="Helper/12">{props.t(".title2")}</Typography>
        </div>

        <Form style={{ width: "100%" }}>
          <Field
            fast
            component={TextField}
            name="email"
            label={props.t(".emailField")}
            fullWidth
            required
          />
          <div style={{ marginTop: 24, marginBottom: 16, width: "100%" }}>
            <Button
              type="submit"
              width="100%"
              disabled={props.isResetPasswordDisabled}
            >
              {props.t(".resetPassword")}
            </Button>
          </div>
          <Button
            variant="outlined"
            onClick={props.handleClickBack}
            width="100%"
          >
            {props.t(".home")}
          </Button>
        </Form>
      </Container>
    </Box>
  </Box>
);

const API = {
  BACKOFFICE_USER_RESET_PASSWORD: gql`
    mutation BACKOFFICE_USER_RESET_PASSWORD($email: String!) {
      backofficeResetPassword(input: { email: $email })
    }
  `,
};

const enhancer = compose(
  withFormik({
    mapPropsToValues: () => ({
      email: "",
    }),
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .transform((originalValue) => originalValue.trim())
        .email(".invalidEmailSyntax"),
    }),
  }),
  withTranslation({ prefix: "pages.main.authentication.ResetPassword" }),
  withHooks((props: any, hooks: any) => {
    const { useHandleSubmit, useMemo, useCallback, useMutation } = hooks;
    const { values, t } = props;

    const [backofficeResetPassword] = useMutation(
      API.BACKOFFICE_USER_RESET_PASSWORD,
      {
        onCompleted: (data: any) => {
          if (data.backofficeResetPassword)
            Modal.alert({
              title: t(".modal.title"),
              children: t(".modal.content"),
              okButtonLabel: t(".modal.button"),
              okButtonVariant: "contained",
              onOk: async ({ ...props }) => {
                paths.homePath().push();
                props.close();
              },
              disableBackdropClick: true,
            });
        },
      }
    );

    const isResetPasswordDisabled = useMemo(() => !values.email, [values]);

    const handleClickBack = useCallback(() => {
      paths.homePath().push();
    }, []);

    useHandleSubmit(async (values: { email: string }) => {
      await backofficeResetPassword({
        variables: {
          email: values.email.trim(),
        },
      });
    });

    return {
      handleClickBack,
      isResetPasswordDisabled,
    };
  })
);

export const ResetPasswordPage = enhancer(ResetPasswordPageComponent);
