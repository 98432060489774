import { GridColDef } from "@material-ui/data-grid";
import { ReactComponent as AddIcon } from "assets/icon/add-icon.svg";
import {
  Box,
  ExcelGenerator,
  Modal,
  Notification,
  Table,
  Typography,
} from "components";
import { PERMISSIONS } from "constants/enums/permissions";
import dayjs from "dayjs";
import { compose, withHooks, withTranslation, withAuthorize } from "enhancers";
import { PageContent } from "layouts";
import { BreadcrumbsProps } from "layouts/PageContent";
import { isEmpty } from "lodash";
import { formatDate, gql, homePath, paths } from "utils/helper";
import { ReactComponent as Trash } from "assets/icon/orange_trash.svg";
import appStore from "stores/appStore";
import { AppColor } from "theme/app-color";
const AdminIndexPage = (props: any) => (
  <PageContent
    title={props.t(".title")}
    breadcrumbs={props.breadcrumbs}
    pageActions={[
      {
        children: props.t(".addButton"),
        startIcon: <AddIcon />,
        onClick: () => paths.adminNewPath().push(),
        color: "primary",
        permittedRoles: ["ADMIN_MANAGEMENT_EDIT"],
      },
    ]}
  >
    <Box width="100%" mb={-6}>
      <Typography variant="h4" mb={4}>
        {props.t(".admins")}
      </Typography>
      <Table
        columns={props.columns}
        rows={props.tableData}
        loading={props.loading}
        onRowClickTo={paths.adminEditPath}
        density="compact"
        autoHeight
        disableSelectionOnClick
      />
    </Box>
  </PageContent>
);

export const API = {
  FETCH_ADMINS: gql`
    query FETCH_ADMINS {
      admins {
        code
        createdAt
        firstName
        lastName
        id
        updatedAt
        role
        backofficeUser {
          email
          availableStatus
        }
      }
    }
  `,
  DELETE_ADMIN: gql`
    mutation DELETE_ADMIN($id: String) {
      deleteAdmin(input: { id: $id })
    }
  `,
};

const enhancer = compose(
  withAuthorize(),
  withTranslation({
    prefix: "pages.main.admins.index",
  }),
  withHooks((props: any, hooks: any) => {
    const { t, hasPermission } = props;
    const { useMemo, useCallback, useQuery, useMutation } = hooks;
    const { loading, data, error, refetch } = useQuery(API.FETCH_ADMINS, {
      fetchPolicy: "network-only",
    });
    const [deleteAdmin] = useMutation(API.DELETE_ADMIN, {
      onCompleted: () => {
        Notification.success(t(".remove"));
      },
    });
    const currentUser = appStore.state.currentUser;
    const hasEditPermission = useMemo(
      () => hasPermission([PERMISSIONS.ADMIN_MANAGEMENT_EDIT]),
      [hasPermission]
    );
    const removeAdmin = useCallback(
      (
        id: string,
        firstName: string,
        lastName: string,
        email: string
      ) => () => {
        const name = `${firstName || ""} ${lastName || ""}`.trim();
        Modal.open({
          title: t(".removeTitle"),
          children: (
            <Box
              flex={1}
              mb={5}
              display="flex"
              flexDirection="column"
              style={{ gap: "4px" }}
            >
              <Box display="flex" flexDirection="row" style={{ gap: "4px" }}>
                <Typography variant="body1">{t(".titleRemove")}</Typography>
                <Typography variant="body2">"{name || email}"</Typography>
              </Box>
              <Typography variant="body1">{t(".removeText")}</Typography>
            </Box>
          ),
          cancelButtonLabel: t(".cancel"),
          okButtonLabel: t(".removeAdmin"),
          okButtonVariant: "contained",
          okButtonColor: AppColor["Remove/Admin"],
          cancelButtonVariant: "outlined",
          onOk: async ({ close }: any) => {
            await deleteAdmin({ variables: { id } });
            await refetch();
            close();
          },
        });
      },
      [deleteAdmin, refetch, t]
    );
    const tableData = useMemo(() => {
      if (loading || error) {
        return [];
      }
      return data.admins.map((admin: any) => {
        const { firstName, lastName, id, backofficeUser, role } = admin;
        const { availableStatus, email } = backofficeUser;

        return {
          ...admin,
          email,
          role: role,
          availableStatus: availableStatus
            ? t(".columns.values.availableStatus.active")
            : t(".columns.values.availableStatus.inactive"),
          fullName:
            (isEmpty(firstName) || firstName == null) &&
            (isEmpty(lastName) || lastName == null)
              ? null
              : `${firstName || ""} ${lastName || ""}`,
          actions:
            !hasEditPermission ||
            currentUser.email === admin.backofficeUser.email
              ? []
              : [
                  {
                    Icon: Trash,
                    onClick: removeAdmin(id, firstName, lastName, email),
                  },
                ],
        };
      });
    }, [loading, error, data, t]);

    const downloadProducts = useCallback(async () => {
      ExcelGenerator.generate({
        fileName: `admins-${formatDate(new Date(), "yyyyMMddhhmmss")}`,
        columns: [
          {
            title: "รหัส",
            field: "code",
          },
          {
            title: "ชื่อ นามสกุล",
            field: "fullName",
          },
          {
            title: "เบอร์โทรศัพท์",
            field: "phoneNumber",
          },
          {
            title: "อีเมล",
            field: "email",
          },
          {
            title: "ไลน์",
            field: "line",
          },
        ],
        data: data.admins,
      });
    }, [data]);

    const breadcrumbs = useMemo(
      (): BreadcrumbsProps[] => [
        { label: t(".home"), path: homePath() },
        { label: t(".title"), path: null },
      ],
      [t]
    );

    const columns = useMemo(
      (): GridColDef[] => [
        { width: 100, field: "code", headerName: t(".columns.code") },
        {
          width: 240,
          field: "fullName",
          headerName: t(".columns.fullName"),
        },
        {
          width: 140,
          field: "role",
          headerName: t(".columns.role"),
        },
        {
          width: 240,
          field: "email",
          headerName: t(".columns.email"),
        },
        {
          width: 160,
          field: "updatedAt",
          headerName: t(".columns.updatedAt"),
          type: "date",
          renderCell: (updatedAt: any) => {
            return dayjs(updatedAt.row.updatedAt)
              .locale("th")
              .add(543, "year")
              .format("DD/MM/YYYY, HH:mm");
          },
        },
        {
          field: "availableStatus",
          headerName: t(".columns.status"),
          type: "tag",
          align: "center",
          headerAlign: "center",
        },
        // {
        //   width: 160,
        //   field: "lastActiveAt",
        //   headerName: t(".columns.lastActiveAt"),
        //   hide: true,
        //   type: "date",
        // },
        // {
        //   width: 160,
        //   field: "createdAt",
        //   headerName: t(".columns.createdAt"),
        //   hide: true,
        //   type: "date",
        // },
        {
          width: 40,
          field: "actions",
          headerName: " ",
          filterable: false,
          sortable: false,
          type: "actions",
        },
      ],
      [t]
    );

    return {
      breadcrumbs,
      tableData,
      loading,
      downloadProducts,
      columns,
    };
  })
);

export default enhancer(AdminIndexPage);
